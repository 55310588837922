import { SpinnerModal } from 'js/components/SpinnerModal/SpinnerModal.js';

const Raven = require('raven-js/dist/raven.js');
Raven.config('https://13d7b1cf5db9462a8a6121dfd4d032c5@sentry.io/751790').install();
const URI = require('urijs');

import { HTML5Geolocation } from 'js/components/Util/Html5Geolocation.js';
HTML5Geolocation.initClass();

export class Hero {
  constructor() {
    this.inputEl = document.querySelector('.Hero-input');
    this.form = document.querySelector('.Hero-form');
    const geolocateButton = this.form.querySelector('.js-hero-geolocateTrigger');
    if (geolocateButton) {
      geolocateButton.addEventListener('click', () => {this.geolocate(this.inputEl)});
    }
  }

  async geolocate(input) {
    HTML5Geolocation.getCurrentLocation(async (position) => {
      if (position.hasOwnProperty('latitude') && position.hasOwnProperty('longitude')) {
        this.setQueryParams(position, input, true);
      } else {
        console.log("HTML5 Geolocation: Error no position found.");
      }
    }, () => {
      console.log("HTML5 Geolocation: Error no position found.");
    });
  }

  async setQueryParams(position, input, isHTML5) {
    const currentParams = new URI(window.location.search);
    currentParams.addSearch(`q`, `${position.latitude},${position.longitude}`);
    currentParams.addSearch('qp', 'my location');
    window.location.href = this.form.getAttribute('action') + currentParams;
  }
}
